import { Button, TextField, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lessPaddingTitle: {
      paddingBottom: "2px",
    },
    lessPaddingContent: {
      paddingTop: "2px",
      paddingBottom: "2px",
    }
  }),
);

export default function EditValueDialogComponent(props: {
  isDialogOpen: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
  title?: string;
  description?: string;
  valueValidation?: (value: string) => boolean;
  valueInvalidText?: string;
  defaultValue?: string
}) {

  const [isValueValid, setIsValueValid] = useState(true);
  const [value, setValue] = useState("");

  const classes = useStyles();
  return <>
    <Dialog
      open={props.isDialogOpen}
      onClose={() => {
        setValue("");
        props.onClose()
      }}
      fullWidth
      aria-labelledby="edit-dialog-slide-title"
      aria-describedby="edit-dialog-slide-description"
    >
      <DialogTitle className={classes.lessPaddingTitle}
        id="edit-dialog-slide-title">
        {props.title ?? "Edit Value"}
      </DialogTitle>
      <DialogContent className={classes.lessPaddingContent}>
        {props.valueValidation && props.valueInvalidText ?
          <TextField
            error={!isValueValid}
            helperText={!isValueValid ? props.valueInvalidText : ""}
            autoFocus={true}
            margin="dense"
            label={props.description ?? "Enter new value"}
            fullWidth
            onChange=
            {event => {
              setValue(event.target.value);
              setIsValueValid(!props.valueValidation || props.valueValidation(event.target.value));
            }}
          /> :
          <TextField
            autoFocus={true}
            margin="dense"
            label={props.description ?? "Enter new value"}
            fullWidth
            onChange=
            {event => setValue(event.target.value)}
				defaultValue={props.defaultValue}
          />}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            props.onClose()
          }
          color="default">
          Cancel
          </Button>
        <Button
          onClick={() =>
            props.onSubmit(value)
          }
          color="primary">
          Submit
          </Button>
      </DialogActions>
    </Dialog>
  </>
}